<template>
  <ion-page id="page-content">
    <ion-modal :is-open="showSort" @didDismiss="showSort = false" :initialBreakpoint="$store.state.deviceHeight < 700 ? 0.45 : 0.4" css-class="trans-sort-modal">
      <div class="modal-content trans-sort">
        <div class="title-box">
          <span class="close" @click="showSort = false"></span>
          <span class="font-18 main-color font-b">Select month</span>
          <span class="font-14 sub-color" @click="monthSelect">Apply</span>
        </div>
        <ul class="list-box">
          <li>
            <p class="font-16 label-color">This Month</p>
            <button class="check-btn" @click="monthPick = 0" :class="{ active: monthPick == 0 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past month</p>
            <button class="check-btn" @click="monthPick = 1" :class="{ active: monthPick == 1 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 2 Months</p>
            <button class="check-btn" @click="monthPick = 2" :class="{ active: monthPick == 2 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 3 Months</p>
            <button class="check-btn" @click="monthPick = 3" :class="{ active: monthPick == 3 }"></button>
          </li>
        </ul>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" title="Collector Card History" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }" :showBack="true"></topbar>
      <div class="transaction-page margin-top-160">
        <div class="sort-box">
          <img style="margin-right: 10px" src="../assets/icon-calendar.png" />
          <span @click="showSort = true" class="font-16 sub-color font-m"> Select month </span>
        </div>
        <ul class="list-box">
          <li v-for="(item, index) in stampList" :key="index">
            <div class="detail">
              <p class="font-18 main-color font-m Medium">{{ item.stampCardName }}</p>
              <p class="font-16 gery-color font-m Medium">Date:{{ item.createdTime ? item.createdTime.replaceAll("-", "/") : item.createdTime }}</p>
              <p class="font-16 gery-color font-m Medium" v-if="item.type == 2">Transfer to {{ item.firstName }}</p>
              <p class="font-16 gery-color font-m Medium" v-else-if="item.type == 3">Transfer from {{ item.firstName }}</p>
              <p class="font-16 gery-color font-m Medium" v-else>Transaction id : {{ item.transNo }}</p>
            </div>
            <p class="price font-24 main-color font-m Medium">
              <span v-if="item.type == 2 || item.type == 4 || item.type == 5 || item.type == 7 || item.type == 9">-</span><span v-else>+</span>{{ item.stampQty }}
            </p>
          </li>
        </ul>
        <ion-infinite-scroll @ionInfinite="loadData" threshold="100px" id="infinite-scroll" :disabled="isDisabled">
          <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data..."> </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "collectorHistory",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonInfiniteScroll, IonInfiniteScrollContent },
  data() {
    return {
      showSort: false,
      stampList: [],
      pageNum: 0,
      pageLimit: 20,
      isDisabled: false,
      monthPick: 0,
      startTime: this.$store.state.nowMonthStart,
      endTime: this.$store.state.nowMonthEnd,
      monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
    };
  },
  methods: {
    async init(e) {
      const objdata = this.getAuthObj();
      objdata.couponId = this.$route.params.id;
      objdata.pageNum = this.pageNum;
      objdata.pageLimit = this.pageLimit;
      objdata.stampCardType = 2;
      objdata.startTime = this.startTime;
      objdata.endTime = this.endTime;
      objdata.apiUrl = this.apiConfig.listUserStampHistory;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (this.pageNum == 0) {
        this.stampList = [];
      }
      data.data.result.forEach(item => {
        this.stampList.push(item);
      });
      if (e) {
        e.target.complete();
      }
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled = true;
      }
    },
    monthSelect() {
      switch (this.monthPick) {
        case 0:
          this.startTime = this.$store.state.nowMonthStart;
          this.endTime = this.$store.state.nowMonthEnd;
          break;
        case 1:
          this.startTime = this.$store.state.lastMonthStart;
          this.endTime = this.$store.state.lastMonthEnd;
          break;
        case 2:
          this.startTime = this.$store.state.pastMonthStart;
          this.endTime = this.$store.state.pastMonthEnd;
          break;
        case 3:
          this.startTime = this.$store.state.lPastMonthStart;
          this.endTime = this.$store.state.lPastMonthEnd;
          break;
      }
      this.showSort = false;

      this.init();
    },
    loadData(e) {
      this.pageNum++;
      this.init(e);
    },
  },
  ionViewWillEnter() {
    this.stampList = [];
    this.pageNum = 0;
    this.$store.state.exitApp = false;
    this.init();
    this.sendGAPageView("Collector Card History");
  },
};
</script>
